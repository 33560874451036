import { createContext } from 'react';
import authState, { initialAuthState } from 'context/state/authState';
import loadingState, { initialLoadingState } from 'context/state/loadingState';
import productTypeState, { initialProductTypeState } from 'context/state/productTypeState';
import branchLocationState, { initiateBranchState } from 'context/state/branchLocationState';
import roleState, { initialRoleState } from 'context/state/roleState';
import laneState, { initiateLaneState } from 'context/state/laneState';
import poolState, { initiatePoolState } from 'context/state/poolState';
import userState, { initiateUserState } from 'context/state/userState';
import customerState, { initiateCustomerState } from 'context/state/customerState';
import segmentState, { initiateSegmentState } from 'context/state/segmenState';
import tagState, { initiateTagState } from 'context/state/tagState';

const initialState = {
  auth: initialAuthState,
  loading: initialLoadingState,
  productType: initialProductTypeState,
  branchLocation: initiateBranchState,
  role: initialRoleState,
  lane: initiateLaneState,
  pool: initiatePoolState,
  user: initiateUserState,
  customer: initiateCustomerState,
  segment: initiateSegmentState,
  tag: initiateTagState,
};

export const AppContext = createContext({ ...initialState });

const storage = () => ({
  auth: authState(),
  loading: loadingState(),
  productType: productTypeState(),
  branchLocation: branchLocationState(),
  role: roleState(),
  lane: laneState(),
  pool: poolState(),
  user: userState(),
  customer: customerState(),
  segment: segmentState(),
  tag: tagState(),
});

export default storage;

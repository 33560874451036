import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getUsersAPI } from 'services/msl/user';

export default function userState() {
  const [users, setUsers] = useState([]);
  const fetchAPI = useFetch();

  const getUsers = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getUsersAPI(token, '?page=1&limit=1000'), options);

    setUsers(res.data.users);
  }, []);

  return { users, getUsers };
}

export const initiateUserState = {
  users: [],
  getUsers: () => alert('getUsers: unhandled function'),
};

import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAppState from 'context/useAppState';

export default function ProtectedRoute() {
  const { isAuthenticated } = useAppState((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectLink = encodeURIComponent(location.pathname + location.search);

    if (isAuthenticated) return;

    navigate(`/login?continue=${redirectLink}`, { replace: true, state: location });
  }, [navigate, location, isAuthenticated]);

  return (
    <Suspense fallback="...">
      <Outlet />
    </Suspense>
  );
}

import { MSL_API_URL } from 'environment';

const AUCTION_LANE_BASE_URL = `${MSL_API_URL}/jalur-lelang`;

export async function getLanesAPI(token) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${AUCTION_LANE_BASE_URL}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

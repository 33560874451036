import useAppState from 'context/useAppState';
import { toast } from 'react-hot-toast';

export default function useFetch() {
  const { loading, auth } = useAppState((state) => state);
  const { openLoadingScreen, closeLoadingScreen } = loading;
  const { access_token } = auth.data;

  async function fetchAPI(callbackPromise, options = defaultOptions) {
    options = { ...defaultOptions, ...options };

    const { loadingMessage, successMessage, errorMessage, withToast, withLoadingScreen } = options;

    let result;

    if (withToast) {
      result = await toast.promise(fetcher(callbackPromise, withLoadingScreen, access_token), {
        error: (err) => errorMessage ?? err,
        success: (res) => successMessage ?? res.message,
        loading: loadingMessage ?? 'loading',
      });
    } else {
      try {
        result = await fetcher(callbackPromise, withLoadingScreen, access_token);
      } catch (error) {
        const rejectPromise = () => Promise.reject(error);

        result = await toast.promise(rejectPromise(), {
          error: (err) => errorMessage ?? err,
          success: (res) => successMessage ?? res.message,
          loading: loadingMessage ?? 'loading',
        });
      }
    }

    return result;
  }

  const fetcher = async (callbackPromise, withLoadingScreen, access_token) => {
    try {
      if (withLoadingScreen) openLoadingScreen();

      const result = await callbackPromise(access_token);

      if (!result) return Promise.reject('Something went wrong.');

      if (result.name === 'SyntaxError') return Promise.reject(result.message);

      if (result.name === 'TypeError') return Promise.reject(result.message);

      if (!result.meta) return Promise.reject(JSON.stringify(result));

      if (result.meta.http_status === 401 && access_token) auth.clearAuthState();

      if (result.errors)
        return Promise.reject(result.errors.map((error) => error.message).join('\n'));

      if (result.meta.http_status >= 400 && result.message) return Promise.reject(result.message);

      return Promise.resolve(result);
    } catch (error) {
      Promise.reject(error);
    } finally {
      if (withLoadingScreen) closeLoadingScreen();
    }
  };

  return fetchAPI;
}

const defaultOptions = {
  withToast: true,
  withLoadingScreen: true,
  errorMessage: null,
  successMessage: null,
  loadingMessage: null,
};

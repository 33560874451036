/* eslint-disable indent */
import { lazy } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';

const RootLayout = lazy(() => import('app/layout'));
const LoginPage = lazy(() => import('app/login/page'));
const DashboardPage = lazy(() => import('app/dashboard/page'));
const SettingPage = lazy(() => import('app/settings/page'));
const TagPage = lazy(() => import('app/master/product/tag/page'));
const SegmenPage = lazy(() => import('app/master/product/segmen/page'));
const RolePage = lazy(() => import('app/settings/role/page'));
const UpdateRolePage = lazy(() => import('app/settings/role/[id]/page'));
const AuctionPage = lazy(() => import('app/auction/page'));
const AuctionDetailPage = lazy(() => import('app/auction/[id]/page'));
const AuctionDetailUpdatePage = lazy(() => import('app/auction/[id]/update/page'));
const AuctionDetailLotPage = lazy(() => import('app/auction/[id]/lot/page'));
const AuctionDetailPricingPage = lazy(() => import('app/auction/[id]/pricing/page'));
const AuctionDetailAllocationPage = lazy(() => import('app/auction/[id]/allocation/[product_type_id]/page'));
const BannerPage = lazy(() => import('app/content/banner/page'));
const ContentBlogPage = lazy(() => import('app/content/blog/page'));
const ContentAboutPage = lazy(() => import('app/content/about/page'));
const ContentAboutDetailPage = lazy(() => import('app/content/about/[id]/page'));
const ContentAboutServicePage = lazy(() => import('app/content/about/service/[id]/page'));
const ContentFAQPage = lazy(() => import('app/content/faq/page'));
const ContentFAQDetailPage = lazy(() => import('app/content/faq/[id]/page'));
const ContentBlogDetailPage = lazy(() => import('app/content/blog/[id]/page'));
const ContentProcedurePage = lazy(() => import('app/content/procedure/page'));
const ContentProcedureDetailPage = lazy(() => import('app/content/procedure/[id]/[procedure_id]/page'));
const VehicleReleasePage = lazy(() => import('app/vehicle-release/page'));
const AcceptanceWebPage = lazy(() => import('app/acceptance/web/page'));
const AcceptanceWebAddPage = lazy(() => import('app/acceptance/web/add/page'));
const AcceptanceWebConfirmPage = lazy(() => import('app/acceptance/web/confirm/page'));
const AcceptancePartnerPage = lazy(() => import('app/acceptance/partner/page'));
const AcceptancePartnerDetailPage = lazy(() => import('app/acceptance/partner/[id]/page'));
const MobilizationPage = lazy(() => import('app/mobilization/page'));
const MobilizationConfirmPage = lazy(() => import('app/mobilization/confirm/[id]/page'));
const MobilizationDetailPage = lazy(() => import('app/mobilization/detail/[id]/page'));
const MobilizationDetailInquiryPage = lazy(() => import('app/mobilization/detail/[id]/inquiry/page'));
const ReviewDocumentPage = lazy(() => import('app/review-document/page'));
const ReviewDocumentDetailPage = lazy(() => import('app/review-document/[id]/page'));
const MasterProductTypePage = lazy(() => import('app/master/product/type/page'));
const MasterProductTypeDetailPage = lazy(() => import('app/master/product/type/[id]/page'));
const MasterProductTypeUpdatePage = lazy(() => import('app/master/product/type/[id]/update/page'));
const MasterInspectionAssessmentPage = lazy(() => import('app/master/inspection/assessment/page'));
const InventoryCarPage = lazy(() => import('app/inventory/page'));
const InventoryTypeAddPage = lazy(() => import('app/inventory/[type]/add/page'));
const InventoryDetailPage = lazy(() => import('app/inventory/[id]/page'));
const EditUnitInformationPage = lazy(() => import('app/inventory/[id]/unit/page'));
const EditDocumentPage = lazy(() => import('app/inventory/[id]/document/page'));
const ShowInspectionPage = lazy(() => import('app/inventory/[id]/inspection/page'));
const StatementAuctionPage = lazy(() => import('app/statement/auction/page'));
const StatementAuctionDetailPage = lazy(() => import('app/statement/auction/[id]/page'));
const StatementAuctionTransactionPage = lazy(() => import('app/statement/auction/transaction/[id]/page'));
const StatementMOUPage = lazy(() => import('app/statement/mou/page'));
const StatementSellerPaymentPage = lazy(() => import('app/statement/seller-payment/page'));
const StatementSellerPaymentDetailPage = lazy(() => import('app/statement/seller-payment/[id]/page'));
const StatementSellerDetailPage = lazy(() => import('app/statement/seller-payment/[id]/[seller_id]/page'));
const CustomerPage = lazy(() => import('app/customer/page'));
const CustomerAddPage = lazy(() => import('app/customer/add/page'));
const CustomerUpdatePage = lazy(() => import('app/customer/update/[id]/page'));
const CustomerDetailPage = lazy(() => import('app/customer/[id]/page'));
const MasterInspectionCategoryPage = lazy(() => import('app/master/inspection/category/page'));
const MasterInspectionPartPage = lazy(() => import('app/master/inspection/part/page'));

export default function RouteList() {
  return (
    <Switch>
      <Route>
        <Route element={<AuthRoute />} path="login">
          <Route element={<LoginPage />} index />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<AuctionDetailLotPage />} path="auction/:id/lot" />
          <Route element={<AuctionDetailPricingPage />} path="auction/:id/pricing" />
          <Route element={<AuctionDetailAllocationPage />} path="auction/:id/allocation/:product_type_id" />
          <Route element={<MasterProductTypeUpdatePage />} path="master/product/type/:id/update" />
          <Route element={<ContentAboutDetailPage />} path="content/about/:id" />
          <Route element={<RootLayout />}>
            <Route element={<Navigate replace to="/dashboard" />} index />
            <Route element={<DashboardPage />} path="dashboard" />
            <Route element={<ReviewDocumentPage />} path="review-document" />
            <Route element={<ReviewDocumentDetailPage />} path="review-document/:id" />
            <Route element={<SettingPage />} path="settings" />
            <Route element={<RolePage />} path="settings/role" />
            <Route element={<UpdateRolePage />} path="settings/role/:id" />
            <Route element={<MasterProductTypePage />} path="master/product/type" />
            <Route element={<MasterProductTypeDetailPage />} path="master/product/type/:id" />
            <Route element={<MasterInspectionPartPage />} path="master/inspection/part" />
            <Route element={<MasterInspectionCategoryPage />} path="master/inspection/category" />
            <Route element={<MasterInspectionAssessmentPage />} path="master/inspection/assessment" />
            <Route element={<AuctionPage />} path="auction" />
            <Route element={<AuctionDetailPage />} path="auction/:id" />
            <Route element={<AuctionDetailUpdatePage />} path="auction/:id/update" />
            <Route element={<BannerPage />} path="content/banner" />
            <Route element={<ContentBlogPage />} path="content/blog" />
            <Route element={<ContentBlogDetailPage />} path="content/blog/:id" />
            <Route element={<ContentAboutPage />} path="content/about" />
            <Route element={<ContentAboutServicePage />} path="content/about/service/:id" />
            <Route element={<ContentFAQPage />} path="content/faq" />
            <Route element={<ContentFAQDetailPage />} path="content/faq/:id/:faq_id" />
            <Route element={<ContentProcedurePage />} path="content/procedure" />
            <Route element={<ContentProcedureDetailPage />} path="content/procedure/:id/:procedure_id" />
            <Route element={<VehicleReleasePage />} path="vehicle-release" />
            <Route element={<AcceptanceWebPage />} path="acceptance/web" />
            <Route element={<AcceptanceWebAddPage />} path="acceptance/web/add" />
            <Route element={<AcceptanceWebConfirmPage />} path="acceptance/web/:id/confirm" />
            <Route element={<MobilizationPage />} path="mobilization" />
            <Route element={<MobilizationConfirmPage />} path="mobilization/confirm/:id" />
            <Route element={<MobilizationDetailPage />} path="mobilization/detail/:id" />
            <Route element={<MobilizationDetailInquiryPage />} path="mobilization/detail/:id/inquiry" />
            <Route element={<AcceptancePartnerPage />} path="acceptance/partner" />
            <Route element={<AcceptancePartnerDetailPage />} path="acceptance/partner/:id" />
            <Route element={<InventoryCarPage />} path="inventory" />
            <Route element={<InventoryTypeAddPage />} path="inventory/:type/add" />
            <Route element={<InventoryDetailPage />} path="inventory/:id" />
            <Route element={<ShowInspectionPage />} path="inventory/:id/inspection" />
            <Route element={<EditUnitInformationPage />} path="inventory/:id/unit" />
            <Route element={<EditDocumentPage />} path="inventory/:id/document" />
            <Route element={<TagPage />} path="master/product/tag" />
            <Route element={<SegmenPage />} path="master/product/segmen" />
            <Route element={<StatementAuctionPage />} path="statement/auction" />
            <Route element={<StatementAuctionDetailPage />} path="statement/auction/:id" />
            <Route element={<StatementAuctionTransactionPage />} path="statement/auction/transaction/:id" />
            <Route element={<StatementMOUPage />} path="statement/mou" />
            <Route element={<StatementSellerPaymentPage />} path="statement/seller-payment/" />
            <Route element={<StatementSellerPaymentDetailPage />} path="statement/seller-payment/:id" />
            <Route element={<StatementSellerDetailPage />} path="statement/seller-payment/:id/:seller_id" />
            <Route element={<CustomerPage />} path="customer" />
            <Route element={<CustomerAddPage />} path="customer/add" />
            <Route element={<CustomerUpdatePage />} path="customer/update/:id" />
            <Route element={<CustomerDetailPage />} path="customer/:id" />
            <Route element={<p>page not found</p>} path="*" />
          </Route>
        </Route>
      </Route>
    </Switch>
  );
}

import { useState } from 'react';

export default function loadingState() {
  const [isOpen, setOpen] = useState(false);

  return {
    isOpen,
    openLoadingScreen: () => setOpen(true),
    closeLoadingScreen: () => setOpen(false),
  };
}

export const initialLoadingState = {
  isOpen: false,
  openLoadingScreen: () => alert('loadingState: unhandled function'),
  closeLoadingScreen: () => alert('loadingState: unhandled function'),
};

import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getBranchAPI } from 'services/msl/branch';

export default function branchLocationState() {
  const [branchLocations, setBranchLocations] = useState([]);
  const fetchAPI = useFetch();

  const getBranchLocation = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getBranchAPI(token, '?page=1&limit=1000'), options);

    setBranchLocations(res.data.branch_locations);
  }, []);

  return {
    branchLocations,
    getBranchLocation,
  };
}

export const initiateBranchState = {
  branchLocations: [],
  getBranchLocation: () => alert('getBranchLocation: unhandled function'),
};

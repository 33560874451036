import { useContext } from 'react';
import { AppContext } from 'context/store';

export default function useAppState(callback) {
  const context = useContext(AppContext);

  if (callback) return callback(context);

  return context;
}

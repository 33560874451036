import { useCallback, useState } from 'react';
import CryptoJS from 'crypto-js';
import { CRYPTO_SECRET } from 'environment';
import useAppState from 'context/useAppState';

export default function useApp() {
  const [loading, setLoading] = useState(true);

  const { auth, productType, branchLocation, role, lane, pool, user, customer, segment } =
    useAppState();

  const { isAuthenticated, storeAuthState, clearAuthState, data } = auth;

  const checkCredential = useCallback(async () => {
    let credential = { access_token: '', refresh_token: '', expires_in: '', scopes: null };

    const credentialLocal = localStorage.getItem('credential');

    if (credentialLocal) {
      const bytes = CryptoJS.AES.decrypt(credentialLocal, `${CRYPTO_SECRET}`);

      let decryptedCredential;

      try {
        decryptedCredential = bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        clearAuthState();
      }

      if (!decryptedCredential) return clearAuthState();

      credential = JSON.parse(decryptedCredential);
    }

    // unauthenticated
    if (Date.now() > new Date(credential.expires_in)) {
      clearAuthState();

      setLoading(false);

      return;
    }

    // authenticated but state already stored
    if (isAuthenticated) return;

    // authenticated and store the state
    // const [error, res] = await getUserData(credential.token).finally(() => setLoading(false));

    // if (error) return toast.error(error);

    if (credential.access_token) storeAuthState(credential);

    setLoading(false);
  }, [isAuthenticated]);

  const scrollToTop = useCallback(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  const getInitialData = useCallback(() => {
    if (!data.access_token) return;

    productType.getProductType(data.access_token);
    branchLocation.getBranchLocation(data.access_token);
    role.getRole(data.access_token);
    lane.getLanes(data.access_token);
    pool.getPools(data.access_token);
    user.getUsers(data.access_token);
    customer.getCustomers(data.access_token);
    segment.getSegments(data.access_token);
    // tag.getTags(data.access_token);
  }, [
    data.access_token,
    productType.getProductType,
    branchLocation.getBranchLocation,
    role.getRole,
    lane.getLanes,
    pool.getPools,
    pool.getPools,
    user.getUsers,
    customer.getCustomers,
    segment.getSegments,
    // tag.getTags,
  ]);

  return { checkCredential, scrollToTop, getInitialData, loading };
}

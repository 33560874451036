import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getAllProductTypeAPI } from 'services/msl/productType';

export default function productTypeState() {
  const [productTypes, setProductTypes] = useState([]);
  const fetchAPI = useFetch();

  const getProductType = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getAllProductTypeAPI(token, ''), options);

    setProductTypes(res.data.product_types);
  }, []);

  return {
    productTypes,
    getProductType,
  };
}

export const initialProductTypeState = {
  productTypes: [],
  getProductType: () => alert('getProductType: unhandled function'),
};

import { MSL_API_URL } from 'environment';

const PRODUCT_TYPE_URL = `${MSL_API_URL}/admin/master-data/produk/product-type`;

export async function getAllProductTypeAPI(token, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${PRODUCT_TYPE_URL}/all${query && query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getProductTypesAPI(token, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${PRODUCT_TYPE_URL}${query && query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getProductTypeAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${PRODUCT_TYPE_URL}/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function addProductTypeAPI(token, body) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify(body);

  const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${PRODUCT_TYPE_URL}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateProductTypeAPI(token, body, id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify(body);

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${PRODUCT_TYPE_URL}/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateProductTypeStatusAPI(token, id, status) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({ status });

  const requestOptions = { method: 'PATCH', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${PRODUCT_TYPE_URL}/change-status/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getLanesAPI } from 'services/msl/lane';

export default function laneState() {
  const [lanes, setLanes] = useState([]);
  const fetchAPI = useFetch();

  const getLanes = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getLanesAPI(token), options);

    setLanes(res.data.auction_lanes);
  }, []);

  return { lanes, getLanes };
}

export const initiateLaneState = {
  lanes: [],
  getLanes: () => alert('getLanes: unhandled function'),
};

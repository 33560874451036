import { MSL_API_URL } from 'environment';

export async function getRolesAPI(token, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/role${query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getRoleDetailAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/role/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function addRoleAPI(token, name, accessId) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({ name, access_id: accessId });

  const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/role`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateRoleAPI(token, id, name, accessId) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({ name, access_id: accessId });
  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/role/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function removeRoleAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'DELETE', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/role/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

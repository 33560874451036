import { useState } from 'react';
import CryptoJS from 'crypto-js';
import { CRYPTO_SECRET } from 'environment';

export default function authState() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [data, setData] = useState(initialData);

  async function storeAuthState(data) {
    setAuthenticated(true);

    setData(data);

    const encryptedCredential = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      `${CRYPTO_SECRET}`,
    ).toString();

    localStorage.setItem('credential', encryptedCredential);
  }

  function clearAuthState() {
    setAuthenticated(false);

    setData({
      access_token: '',
      refresh_token: '',
      expires_in: '',
      scopes: null,
      user: {
        id: 0,
        full_name: '',
        email: '',
        role_id: 0,
        role_name: '',
        role_group: '',
      },
    });

    localStorage.removeItem('credential');
  }

  return { isAuthenticated, storeAuthState, clearAuthState, data };
}

const initialData = {
  access_token: '',
  refresh_token: '',
  expires_in: '',
  scopes: null,
  user: {
    id: 0,
    full_name: '',
    email: '',
    role_id: 0,
    role_name: '',
    role_group: '',
  },
};

export const initialAuthState = {
  data: initialData,
  isAuthenticated: false,
  storeAuthState: () => alert('authState: unhandled function'),
  clearAuthState: () => alert('authState: unhandled function'),
};

import { MSL_API_URL } from 'environment';

export async function getUsersAPI(token, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/user${query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function addUserAPI(token, name, email, phone, password, branchLocationId, roleId) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({
    name,
    email,
    phone,
    password,
    branch_location_id: branchLocationId,
    role_id: roleId,
  });

  const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/user`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateUserAPI(
  id,
  token,
  name,
  email,
  phone,
  password,
  branchLocationId,
  roleId,
) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({
    name,
    email,
    phone,
    password,
    branch_location_id: branchLocationId,
    role_id: roleId,
  });

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/user/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function removeUserAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'DELETE', headers: myHeaders, redirect: 'follow' };

  return fetch(`${MSL_API_URL}/admin/pengaturan/user/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getPoolAPI } from 'services/msl/pool';

export default function poolState() {
  const [pools, setPools] = useState([]);
  const fetchAPI = useFetch();

  const getPools = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getPoolAPI(token, '?page=1&limit=1000'), options);

    setPools(res.data.pool_locations);
  }, []);

  return { pools, getPools };
}

export const initiatePoolState = {
  pools: [],
  getPools: () => alert('getPools: unhandled function'),
};

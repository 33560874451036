import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getCustomersAPI } from 'services/msl/customer';

export default function customerState() {
  const [customers, setCustomers] = useState([]);
  const fetchAPI = useFetch();

  const getCustomers = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getCustomersAPI(token, '?page=1&limit=1000'), options);

    setCustomers(res.data.customers);
  }, []);

  return { customers, getCustomers };
}

export const initiateCustomerState = {
  customers: [],
  getcustomers: () => alert('getCustomers: unhandled function'),
};

import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getTagAPI } from 'services/msl/tag';

export default function tagState() {
  const [tags, setTags] = useState([]);
  const fetchAPI = useFetch();

  const getTags = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getTagAPI(token, '?page=1&limit=1000'), options);

    setTags(res.data.product_tags);
  }, []);

  return { tags, getTags };
}

export const initiateTagState = {
  tags: [],
  getTags: () => alert('getTags: unhandled function'),
};

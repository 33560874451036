import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { getSegmentTypesAPI } from 'services/msl/productSegment';

export default function segmentState() {
  const [segments, setSegments] = useState([]);
  const fetchAPI = useFetch();

  const getSegments = useCallback(async (token) => {
    const options = { withLoadingScreen: false, withToast: false };
    const res = await fetchAPI(() => getSegmentTypesAPI(token, '?page=1&limit=1000'), options);

    setSegments(res.data.segments);
  }, []);

  return { segments, getSegments };
}

export const initiateSegmentState = {
  segments: [],
  getSegments: () => alert('getSegments: unhandled function'),
};
